<template>
  <div>
    <app-cells position="start">
      <app-button @click="onAddButtonHandler" type="button" size="link">
        <img src="@/assets/img/add-icon.svg" alt="">
        Добавить зал
      </app-button>
    </app-cells>
    <v-client-table
      @row-click="onRowClick"
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      ref="tableDefault"
      class="table-default table-default--dynamic"
    />
    <app-sidebar-right
      :title="!isAdd ? 'Редактирование зала' : 'Добавление зала'"
      :class="{'sidebar-manage--full': show_sidebar}" @close-sidebar="show_sidebar = !show_sidebar"
    >
      <form @submit.prevent="onCheckForm">
        <app-form-group label="Название" label-for="name" required>
          <app-input
            v-model="sidebarData.name"
            placeholder="Введите название зала"
            id="name"
            :error="$v.sidebarData.name.$error"
          />
          <template #error>
            <div v-if="$v.sidebarData.name.$dirty && !$v.sidebarData.name.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group label="Описание" label-for="description">
          <app-textarea
            v-model="sidebarData.description"
            placeholder="Введите описание стола"
            id="description"
          />
        </app-form-group>
        <app-form-group>
          <app-checkbox
            label="Активность"
            v-if="!isAdd"
            v-model="sidebarData.active"
          />
        </app-form-group>
        <app-cells>
          <app-button v-if="!isAdd" ref="submit" :disabled="$v.sidebarData.$error">Сохранить</app-button>
          <app-button v-else ref="submit" :disabled="$v.sidebarData.$error">Добавить</app-button>
        </app-cells>
      </form>
<!--      <app-button @click="onDelete" v-if="!isAdd" theme="error" ref="delete">Удалить</app-button>-->
    </app-sidebar-right>
  </div>
</template>

<script>
import { getHalls, postHall, putHall, deleteHall } from '@/http'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'Halls',
  data() {
    return {
      table: {
        items: [],
        columns: ['id', 'name'],
        options: {
          headings: {
            id: 'ID',
            name: 'Название',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 100,
          texts: {
            noResults: 'Нет подходящих записей',
          },
          rowClassCallback: () => 'table-default__row',
          resizableColumns: false
        },
      },
      isAdd: false,
      show_sidebar: false,
      sidebarData: {}
    }
  },
  validations: {
    sidebarData: {
      name: { required },
    }
  },
  created() {
    this.fetchItems()
  },
  methods: {
    fetchItems() {
      getHalls().then(res => { this.table.items = res.data })
    },
    onRowClick(element) {
      this.$v.sidebarData.$reset()
      this.isAdd = false
      this.show_sidebar = true
      this.sidebarData = { ...element.row }
    },
    onAddButtonHandler() {
      this.$v.sidebarData.$reset()
      this.isAdd = true
      this.sidebarData = {}
      this.show_sidebar = true
    },
    onCheckForm() {
      this.$v.sidebarData.$touch()
      if (this.$v.sidebarData.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание',
          text: 'Проверьте правильность заполнения полей формы'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      if (!this.isAdd) {
        putHall(this.sidebarData.id, this.sidebarData)
          .then(() => {
            this.$refs.submit.preload = false
            this.fetchItems()
            this.show_sidebar = false
            this.$notify({
              type: 'success',
              title: 'Успех',
              text: 'Зал обновлен'
            })
          })
          .catch(() => { this.$refs.submit.preload = false })
      } else {
        postHall(this.sidebarData)
          .then(() => {
            this.$refs.submit.preload = false
            this.fetchItems()
            this.show_sidebar = false
            this.$notify({
              type: 'success',
              title: 'Успех',
              text: 'Зал добавлен'
            })
          })
          .catch(() => { this.$refs.submit.preload = false })
      }
    },
    // onDelete() {
    //   deleteHall(this.sidebarData.id)
    //     .then(() => {
    //       this.$refs.submit.delete = false
    //       this.fetchItems()
    //       this.show_sidebar = false
    //       this.$notify({
    //         type: 'success',
    //         title: 'Успех',
    //         text: 'Зал удален'
    //       })
    //     })
    //     .catch(() => { this.$refs.submit.delete = false })
    // }
  }
}
</script>
